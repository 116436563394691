// eslint-disable-next-line import/prefer-default-export
export const I18N_LOCALE_COOKIE = 'checkr_locale';
export const I18N_WORK_LOCATION_COOKIE = 'checkr_work_location';
export const NUMBER_MAP_SYMBOLS_LOCALES = [
  'ar-sa',
  'ar',
  'ar-dz',
  'ar-ly',
  'bn-bd',
  'bn',
  'bo',
  'fa',
  'gu',
  'hi',
  'km',
  'kn',
  'ku',
  'mr',
  'my',
  'ne',
  'pa-in',
  'ta',
];
