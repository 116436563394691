const countries = [
  {
    name: 'options.selectACountry',
    value: '',
    alpha3: 'AFG',
    numeric: '004',
    i18n: true,
  },
  { name: 'Afghanistan', value: 'AF', alpha3: 'AFG', numeric: '004' },
  { name: 'Åland Islands', value: 'AX', alpha3: 'ALA', numeric: '248' },
  { name: 'Albania', value: 'AL', alpha3: 'ALB', numeric: '008' },
  { name: 'Algeria', value: 'DZ', alpha3: 'DZA', numeric: '012' },
  { name: 'American Samoa', value: 'AS', alpha3: 'ASM', numeric: '016' },
  { name: 'Andorra', value: 'AD', alpha3: 'AND', numeric: '020' },
  { name: 'Angola', value: 'AO', alpha3: 'AGO', numeric: '024' },
  { name: 'Anguilla', value: 'AI', alpha3: 'AIA', numeric: '660' },
  { name: 'Antarctica', value: 'AQ', alpha3: 'ATA', numeric: '010' },
  { name: 'Antigua and Barbuda', value: 'AG', alpha3: 'ATG', numeric: '028' },
  { name: 'Argentina', value: 'AR', alpha3: 'ARG', numeric: '032' },
  { name: 'Armenia', value: 'AM', alpha3: 'ARM', numeric: '051' },
  { name: 'Aruba', value: 'AW', alpha3: 'ABW', numeric: '533' },
  { name: 'Australia', value: 'AU', alpha3: 'AUS', numeric: '036' },
  { name: 'Austria', value: 'AT', alpha3: 'AUT', numeric: '040' },
  { name: 'Azerbaijan', value: 'AZ', alpha3: 'AZE', numeric: '031' },
  { name: 'Bahamas (the)', value: 'BS', alpha3: 'BHS', numeric: '044' },
  { name: 'Bahrain', value: 'BH', alpha3: 'BHR', numeric: '048' },
  { name: 'Bangladesh', value: 'BD', alpha3: 'BGD', numeric: '050' },
  { name: 'Barbados', value: 'BB', alpha3: 'BRB', numeric: '052' },
  { name: 'Belarus', value: 'BY', alpha3: 'BLR', numeric: '112' },
  { name: 'Belgium', value: 'BE', alpha3: 'BEL', numeric: '056' },
  { name: 'Belize', value: 'BZ', alpha3: 'BLZ', numeric: '084' },
  { name: 'Benin', value: 'BJ', alpha3: 'BEN', numeric: '204' },
  { name: 'Bermuda', value: 'BM', alpha3: 'BMU', numeric: '060' },
  { name: 'Bhutan', value: 'BT', alpha3: 'BTN', numeric: '064' },
  {
    name: 'Bolivia (Plurinational State of)',
    value: 'BO',
    alpha3: 'BOL',
    numeric: '068',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    value: 'BQ',
    alpha3: 'BES',
    numeric: '535',
  },
  {
    name: 'Bosnia and Herzegovina',
    value: 'BA',
    alpha3: 'BIH',
    numeric: '070',
  },
  { name: 'Botswana', value: 'BW', alpha3: 'BWA', numeric: '072' },
  { name: 'Bouvet Island', value: 'BV', alpha3: 'BVT', numeric: '074' },
  { name: 'Brazil', value: 'BR', alpha3: 'BRA', numeric: '076' },
  {
    name: 'British Indian Ocean Territory (the)',
    value: 'IO',
    alpha3: 'IOT',
    numeric: '086',
  },
  { name: 'Brunei Darussalam', value: 'BN', alpha3: 'BRN', numeric: '096' },
  { name: 'Bulgaria', value: 'BG', alpha3: 'BGR', numeric: '100' },
  { name: 'Burkina Faso', value: 'BF', alpha3: 'BFA', numeric: '854' },
  { name: 'Burundi', value: 'BI', alpha3: 'BDI', numeric: '108' },
  { name: 'Cabo Verde', value: 'CV', alpha3: 'CPV', numeric: '132' },
  { name: 'Cambodia', value: 'KH', alpha3: 'KHM', numeric: '116' },
  { name: 'Cameroon', value: 'CM', alpha3: 'CMR', numeric: '120' },
  { name: 'Canada', value: 'CA', alpha3: 'CAN', numeric: '124' },
  { name: 'Cayman Islands (the)', value: 'KY', alpha3: 'CYM', numeric: '136' },
  {
    name: 'Central African Republic (the)',
    value: 'CF',
    alpha3: 'CAF',
    numeric: '140',
  },
  { name: 'Chad', value: 'TD', alpha3: 'TCD', numeric: '148' },
  { name: 'Chile', value: 'CL', alpha3: 'CHL', numeric: '152' },
  { name: 'China', value: 'CN', alpha3: 'CHN', numeric: '156' },
  { name: 'Christmas Island', value: 'CX', alpha3: 'CXR', numeric: '162' },
  {
    name: 'Cocos (Keeling) Islands (the)',
    value: 'CC',
    alpha3: 'CCK',
    numeric: '166',
  },
  { name: 'Colombia', value: 'CO', alpha3: 'COL', numeric: '170' },
  { name: 'Comoros (the)', value: 'KM', alpha3: 'COM', numeric: '174' },
  { name: 'Congo (the)', value: 'CG', alpha3: 'COG', numeric: '178' },
  {
    name: 'Congo (the Democratic Republic of the)',
    value: 'CD',
    alpha3: 'COD',
    numeric: '180',
  },
  { name: 'Cook Islands (the)', value: 'CK', alpha3: 'COK', numeric: '184' },
  { name: 'Costa Rica', value: 'CR', alpha3: 'CRI', numeric: '188' },
  { name: "Côte d'Ivoire", value: 'CI', alpha3: 'CIV', numeric: '384' },
  { name: 'Croatia', value: 'HR', alpha3: 'HRV', numeric: '191' },
  { name: 'Cuba', value: 'CU', alpha3: 'CUB', numeric: '192' },
  { name: 'Curaçao', value: 'CW', alpha3: 'CUW', numeric: '531' },
  { name: 'Cyprus', value: 'CY', alpha3: 'CYP', numeric: '196' },
  { name: 'Czech Republic (the)', value: 'CZ', alpha3: 'CZE', numeric: '203' },
  { name: 'Denmark', value: 'DK', alpha3: 'DNK', numeric: '208' },
  { name: 'Djibouti', value: 'DJ', alpha3: 'DJI', numeric: '262' },
  { name: 'Dominica', value: 'DM', alpha3: 'DMA', numeric: '212' },
  {
    name: 'Dominican Republic (the)',
    value: 'DO',
    alpha3: 'DOM',
    numeric: '214',
  },
  { name: 'Ecuador', value: 'EC', alpha3: 'ECU', numeric: '218' },
  { name: 'Egypt', value: 'EG', alpha3: 'EGY', numeric: '818' },
  { name: 'El Salvador', value: 'SV', alpha3: 'SLV', numeric: '222' },
  { name: 'Equatorial Guinea', value: 'GQ', alpha3: 'GNQ', numeric: '226' },
  { name: 'Eritrea', value: 'ER', alpha3: 'ERI', numeric: '232' },
  { name: 'Estonia', value: 'EE', alpha3: 'EST', numeric: '233' },
  { name: 'Ethiopia', value: 'ET', alpha3: 'ETH', numeric: '231' },
  {
    name: 'Falkland Islands (the) [Malvinas]',
    value: 'FK',
    alpha3: 'FLK',
    numeric: '238',
  },
  { name: 'Faroe Islands (the)', value: 'FO', alpha3: 'FRO', numeric: '234' },
  { name: 'Fiji', value: 'FJ', alpha3: 'FJI', numeric: '242' },
  { name: 'Finland', value: 'FI', alpha3: 'FIN', numeric: '246' },
  { name: 'France', value: 'FR', alpha3: 'FRA', numeric: '250' },
  { name: 'French Guiana', value: 'GF', alpha3: 'GUF', numeric: '254' },
  { name: 'French Polynesia', value: 'PF', alpha3: 'PYF', numeric: '258' },
  {
    name: 'French Southern Territories (the)',
    value: 'TF',
    alpha3: 'ATF',
    numeric: '260',
  },
  { name: 'Gabon', value: 'GA', alpha3: 'GAB', numeric: '266' },
  { name: 'Gambia (the)', value: 'GM', alpha3: 'GMB', numeric: '270' },
  { name: 'Georgia', value: 'GE', alpha3: 'GEO', numeric: '268' },
  { name: 'Germany', value: 'DE', alpha3: 'DEU', numeric: '276' },
  { name: 'Ghana', value: 'GH', alpha3: 'GHA', numeric: '288' },
  { name: 'Gibraltar', value: 'GI', alpha3: 'GIB', numeric: '292' },
  { name: 'Greece', value: 'GR', alpha3: 'GRC', numeric: '300' },
  { name: 'Greenland', value: 'GL', alpha3: 'GRL', numeric: '304' },
  { name: 'Grenada', value: 'GD', alpha3: 'GRD', numeric: '308' },
  { name: 'Guadeloupe', value: 'GP', alpha3: 'GLP', numeric: '312' },
  { name: 'Guam', value: 'GU', alpha3: 'GUM', numeric: '316' },
  { name: 'Guatemala', value: 'GT', alpha3: 'GTM', numeric: '320' },
  { name: 'Guernsey', value: 'GG', alpha3: 'GGY', numeric: '831' },
  { name: 'Guinea', value: 'GN', alpha3: 'GIN', numeric: '324' },
  { name: 'Guinea-Bissau', value: 'GW', alpha3: 'GNB', numeric: '624' },
  { name: 'Guyana', value: 'GY', alpha3: 'GUY', numeric: '328' },
  { name: 'Haiti', value: 'HT', alpha3: 'HTI', numeric: '332' },
  {
    name: 'Heard Island and McDonald Islands',
    value: 'HM',
    alpha3: 'HMD',
    numeric: '334',
  },
  { name: 'Holy See (the)', value: 'VA', alpha3: 'VAT', numeric: '336' },
  { name: 'Honduras', value: 'HN', alpha3: 'HND', numeric: '340' },
  { name: 'Hong Kong', value: 'HK', alpha3: 'HKG', numeric: '344' },
  { name: 'Hungary', value: 'HU', alpha3: 'HUN', numeric: '348' },
  { name: 'Iceland', value: 'IS', alpha3: 'ISL', numeric: '352' },
  { name: 'India', value: 'IN', alpha3: 'IND', numeric: '356' },
  { name: 'Indonesia', value: 'ID', alpha3: 'IDN', numeric: '360' },
  {
    name: 'Iran (Islamic Republic of)',
    value: 'IR',
    alpha3: 'IRN',
    numeric: '364',
  },
  { name: 'Iraq', value: 'IQ', alpha3: 'IRQ', numeric: '368' },
  { name: 'Ireland', value: 'IE', alpha3: 'IRL', numeric: '372' },
  { name: 'Isle of Man', value: 'IM', alpha3: 'IMN', numeric: '833' },
  { name: 'Israel', value: 'IL', alpha3: 'ISR', numeric: '376' },
  { name: 'Italy', value: 'IT', alpha3: 'ITA', numeric: '380' },
  { name: 'Jamaica', value: 'JM', alpha3: 'JAM', numeric: '388' },
  { name: 'Japan', value: 'JP', alpha3: 'JPN', numeric: '392' },
  { name: 'Jersey', value: 'JE', alpha3: 'JEY', numeric: '832' },
  { name: 'Jordan', value: 'JO', alpha3: 'JOR', numeric: '400' },
  { name: 'Kazakhstan', value: 'KZ', alpha3: 'KAZ', numeric: '398' },
  { name: 'Kenya', value: 'KE', alpha3: 'KEN', numeric: '404' },
  { name: 'Kiribati', value: 'KI', alpha3: 'KIR', numeric: '296' },
  {
    name: "Korea (the Democratic People's Republic of)",
    value: 'KP',
    alpha3: 'PRK',
    numeric: '408',
  },
  {
    name: 'Korea (the Republic of)',
    value: 'KR',
    alpha3: 'KOR',
    numeric: '410',
  },
  { name: 'Kuwait', value: 'KW', alpha3: 'KWT', numeric: '414' },
  { name: 'Kyrgyzstan', value: 'KG', alpha3: 'KGZ', numeric: '417' },
  {
    name: "Lao People's Democratic Republic (the)",
    value: 'LA',
    alpha3: 'LAO',
    numeric: '418',
  },
  { name: 'Latvia', value: 'LV', alpha3: 'LVA', numeric: '428' },
  { name: 'Lebanon', value: 'LB', alpha3: 'LBN', numeric: '422' },
  { name: 'Lesotho', value: 'LS', alpha3: 'LSO', numeric: '426' },
  { name: 'Liberia', value: 'LR', alpha3: 'LBR', numeric: '430' },
  { name: 'Libya', value: 'LY', alpha3: 'LBY', numeric: '434' },
  { name: 'Liechtenstein', value: 'LI', alpha3: 'LIE', numeric: '438' },
  { name: 'Lithuania', value: 'LT', alpha3: 'LTU', numeric: '440' },
  { name: 'Luxembourg', value: 'LU', alpha3: 'LUX', numeric: '442' },
  { name: 'Macao', value: 'MO', alpha3: 'MAC', numeric: '446' },
  {
    name: 'Macedonia (the former Yugoslav Republic of)',
    value: 'MK',
    alpha3: 'MKD',
    numeric: '807',
  },
  { name: 'Madagascar', value: 'MG', alpha3: 'MDG', numeric: '450' },
  { name: 'Malawi', value: 'MW', alpha3: 'MWI', numeric: '454' },
  { name: 'Malaysia', value: 'MY', alpha3: 'MYS', numeric: '458' },
  { name: 'Maldives', value: 'MV', alpha3: 'MDV', numeric: '462' },
  { name: 'Mali', value: 'ML', alpha3: 'MLI', numeric: '466' },
  { name: 'Malta', value: 'MT', alpha3: 'MLT', numeric: '470' },
  {
    name: 'Marshall Islands (the)',
    value: 'MH',
    alpha3: 'MHL',
    numeric: '584',
  },
  { name: 'Martinique', value: 'MQ', alpha3: 'MTQ', numeric: '474' },
  { name: 'Mauritania', value: 'MR', alpha3: 'MRT', numeric: '478' },
  { name: 'Mauritius', value: 'MU', alpha3: 'MUS', numeric: '480' },
  { name: 'Mayotte', value: 'YT', alpha3: 'MYT', numeric: '175' },
  { name: 'Mexico', value: 'MX', alpha3: 'MEX', numeric: '484' },
  {
    name: 'Micronesia (Federated States of)',
    value: 'FM',
    alpha3: 'FSM',
    numeric: '583',
  },
  {
    name: 'Moldova (the Republic of)',
    value: 'MD',
    alpha3: 'MDA',
    numeric: '498',
  },
  { name: 'Monaco', value: 'MC', alpha3: 'MCO', numeric: '492' },
  { name: 'Mongolia', value: 'MN', alpha3: 'MNG', numeric: '496' },
  { name: 'Montenegro', value: 'ME', alpha3: 'MNE', numeric: '499' },
  { name: 'Montserrat', value: 'MS', alpha3: 'MSR', numeric: '500' },
  { name: 'Morocco', value: 'MA', alpha3: 'MAR', numeric: '504' },
  { name: 'Mozambique', value: 'MZ', alpha3: 'MOZ', numeric: '508' },
  { name: 'Myanmar', value: 'MM', alpha3: 'MMR', numeric: '104' },
  { name: 'Namibia', value: 'NA', alpha3: 'NAM', numeric: '516' },
  { name: 'Nauru', value: 'NR', alpha3: 'NRU', numeric: '520' },
  { name: 'Nepal', value: 'NP', alpha3: 'NPL', numeric: '524' },
  { name: 'Netherlands (the)', value: 'NL', alpha3: 'NLD', numeric: '528' },
  { name: 'New Caledonia', value: 'NC', alpha3: 'NCL', numeric: '540' },
  { name: 'New Zealand', value: 'NZ', alpha3: 'NZL', numeric: '554' },
  { name: 'Nicaragua', value: 'NI', alpha3: 'NIC', numeric: '558' },
  { name: 'Niger (the)', value: 'NE', alpha3: 'NER', numeric: '562' },
  { name: 'Nigeria', value: 'NG', alpha3: 'NGA', numeric: '566' },
  { name: 'Niue', value: 'NU', alpha3: 'NIU', numeric: '570' },
  { name: 'Norfolk Island', value: 'NF', alpha3: 'NFK', numeric: '574' },
  {
    name: 'Northern Mariana Islands (the)',
    value: 'MP',
    alpha3: 'MNP',
    numeric: '580',
  },
  { name: 'Norway', value: 'NO', alpha3: 'NOR', numeric: '578' },
  { name: 'Oman', value: 'OM', alpha3: 'OMN', numeric: '512' },
  { name: 'Pakistan', value: 'PK', alpha3: 'PAK', numeric: '586' },
  { name: 'Palau', value: 'PW', alpha3: 'PLW', numeric: '585' },
  { name: 'Palestine, State of', value: 'PS', alpha3: 'PSE', numeric: '275' },
  { name: 'Panama', value: 'PA', alpha3: 'PAN', numeric: '591' },
  { name: 'Papua New Guinea', value: 'PG', alpha3: 'PNG', numeric: '598' },
  { name: 'Paraguay', value: 'PY', alpha3: 'PRY', numeric: '600' },
  { name: 'Peru', value: 'PE', alpha3: 'PER', numeric: '604' },
  { name: 'Philippines (the)', value: 'PH', alpha3: 'PHL', numeric: '608' },
  { name: 'Pitcairn', value: 'PN', alpha3: 'PCN', numeric: '612' },
  { name: 'Poland', value: 'PL', alpha3: 'POL', numeric: '616' },
  { name: 'Portugal', value: 'PT', alpha3: 'PRT', numeric: '620' },
  { name: 'Puerto Rico', value: 'PR', alpha3: 'PRI', numeric: '630' },
  { name: 'Qatar', value: 'QA', alpha3: 'QAT', numeric: '634' },
  { name: 'Réunion', value: 'RE', alpha3: 'REU', numeric: '638' },
  { name: 'Romania', value: 'RO', alpha3: 'ROU', numeric: '642' },
  {
    name: 'Russian Federation (the)',
    value: 'RU',
    alpha3: 'RUS',
    numeric: '643',
  },
  { name: 'Rwanda', value: 'RW', alpha3: 'RWA', numeric: '646' },
  { name: 'Saint Barthélemy', value: 'BL', alpha3: 'BLM', numeric: '652' },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'SH',
    alpha3: 'SHN',
    numeric: '654',
  },
  { name: 'Saint Kitts and Nevis', value: 'KN', alpha3: 'KNA', numeric: '659' },
  { name: 'Saint Lucia', value: 'LC', alpha3: 'LCA', numeric: '662' },
  {
    name: 'Saint Martin (French part)',
    value: 'MF',
    alpha3: 'MAF',
    numeric: '663',
  },
  {
    name: 'Saint Pierre and Miquelon',
    value: 'PM',
    alpha3: 'SPM',
    numeric: '666',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    value: 'VC',
    alpha3: 'VCT',
    numeric: '670',
  },
  { name: 'Samoa', value: 'WS', alpha3: 'WSM', numeric: '882' },
  { name: 'San Marino', value: 'SM', alpha3: 'SMR', numeric: '674' },
  { name: 'Sao Tome and Principe', value: 'ST', alpha3: 'STP', numeric: '678' },
  { name: 'Saudi Arabia', value: 'SA', alpha3: 'SAU', numeric: '682' },
  { name: 'Senegal', value: 'SN', alpha3: 'SEN', numeric: '686' },
  { name: 'Serbia', value: 'RS', alpha3: 'SRB', numeric: '688' },
  { name: 'Seychelles', value: 'SC', alpha3: 'SYC', numeric: '690' },
  { name: 'Sierra Leone', value: 'SL', alpha3: 'SLE', numeric: '694' },
  { name: 'Singapore', value: 'SG', alpha3: 'SGP', numeric: '702' },
  {
    name: 'Sint Maarten (Dutch part)',
    value: 'SX',
    alpha3: 'SXM',
    numeric: '534',
  },
  { name: 'Slovakia', value: 'SK', alpha3: 'SVK', numeric: '703' },
  { name: 'Slovenia', value: 'SI', alpha3: 'SVN', numeric: '705' },
  { name: 'Solomon Islands', value: 'SB', alpha3: 'SLB', numeric: '090' },
  { name: 'Somalia', value: 'SO', alpha3: 'SOM', numeric: '706' },
  { name: 'South Africa', value: 'ZA', alpha3: 'ZAF', numeric: '710' },
  {
    name: 'South Georgia and the South Sandwich Islands',
    value: 'GS',
    alpha3: 'SGS',
    numeric: '239',
  },
  { name: 'South Sudan ', value: 'SS', alpha3: 'SSD', numeric: '728' },
  { name: 'Spain', value: 'ES', alpha3: 'ESP', numeric: '724' },
  { name: 'Sri Lanka', value: 'LK', alpha3: 'LKA', numeric: '144' },
  { name: 'Sudan (the)', value: 'SD', alpha3: 'SDN', numeric: '729' },
  { name: 'Suri name', value: 'SR', alpha3: 'SUR', numeric: '740' },
  {
    name: 'Svalbard and Jan Mayen',
    value: 'SJ',
    alpha3: 'SJM',
    numeric: '744',
  },
  { name: 'Swaziland', value: 'SZ', alpha3: 'SWZ', numeric: '748' },
  { name: 'Sweden', value: 'SE', alpha3: 'SWE', numeric: '752' },
  { name: 'Switzerland', value: 'CH', alpha3: 'CHE', numeric: '756' },
  { name: 'Syrian Arab Republic', value: 'SY', alpha3: 'SYR', numeric: '760' },
  {
    name: 'Taiwan (Province of China)',
    value: 'TW',
    alpha3: 'TWN',
    numeric: '158',
  },
  { name: 'Tajikistan', value: 'TJ', alpha3: 'TJK', numeric: '762' },
  {
    name: 'Tanzania, United Republic of',
    value: 'TZ',
    alpha3: 'TZA',
    numeric: '834',
  },
  { name: 'Thailand', value: 'TH', alpha3: 'THA', numeric: '764' },
  { name: 'Timor-Leste', value: 'TL', alpha3: 'TLS', numeric: '626' },
  { name: 'Togo', value: 'TG', alpha3: 'TGO', numeric: '768' },
  { name: 'Tokelau', value: 'TK', alpha3: 'TKL', numeric: '772' },
  { name: 'Tonga', value: 'TO', alpha3: 'TON', numeric: '776' },
  { name: 'Trinidad and Tobago', value: 'TT', alpha3: 'TTO', numeric: '780' },
  { name: 'Tunisia', value: 'TN', alpha3: 'TUN', numeric: '788' },
  { name: 'Turkey', value: 'TR', alpha3: 'TUR', numeric: '792' },
  { name: 'Turkmenistan', value: 'TM', alpha3: 'TKM', numeric: '795' },
  {
    name: 'Turks and Caicos Islands (the)',
    value: 'TC',
    alpha3: 'TCA',
    numeric: '796',
  },
  { name: 'Tuvalu', value: 'TV', alpha3: 'TUV', numeric: '798' },
  { name: 'Uganda', value: 'UG', alpha3: 'UGA', numeric: '800' },
  { name: 'Ukraine', value: 'UA', alpha3: 'UKR', numeric: '804' },
  {
    name: 'United Arab Emirates (the)',
    value: 'AE',
    alpha3: 'ARE',
    numeric: '784',
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland (the)',
    value: 'GB',
    alpha3: 'GBR',
    numeric: '826',
  },
  {
    name: 'United States Minor Outlying Islands (the)',
    value: 'UM',
    alpha3: 'UMI',
    numeric: '581',
  },
  {
    name: 'United States of America (the)',
    value: 'US',
    alpha3: 'USA',
    numeric: '840',
  },
  { name: 'Uruguay', value: 'UY', alpha3: 'URY', numeric: '858' },
  { name: 'Uzbekistan', value: 'UZ', alpha3: 'UZB', numeric: '860' },
  { name: 'Vanuatu', value: 'VU', alpha3: 'VUT', numeric: '548' },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    value: 'VE',
    alpha3: 'VEN',
    numeric: '862',
  },
  { name: 'Vietnam', value: 'VN', alpha3: 'VNM', numeric: '704' },
  {
    name: 'Virgin Islands (British)',
    value: 'VG',
    alpha3: 'VGB',
    numeric: '092',
  },
  { name: 'Virgin Islands (U.S.)', value: 'VI', alpha3: 'VIR', numeric: '850' },
  { name: 'Wallis and Futuna', value: 'WF', alpha3: 'WLF', numeric: '876' },
  { name: 'Western Sahara', value: 'EH', alpha3: 'ESH', numeric: '732' },
  { name: 'Yemen', value: 'YE', alpha3: 'YEM', numeric: '887' },
  { name: 'Zambia', value: 'ZM', alpha3: 'ZMB', numeric: '894' },
  { name: 'Zimbabwe', value: 'ZW', alpha3: 'ZWE', numeric: '716 ' },
];

export default countries;
