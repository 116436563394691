import _moment from 'moment';
import _momenttz from 'moment-timezone';
import momentLocales from 'moment/min/moment-with-locales';
import { isString, isNumber, camelCase, isArray, omit } from 'lodash';
import renderHTML from 'html-react-parser';
import i18next from 'i18next';
import Cookie from 'js-cookie';
import { withTranslation, initReactI18next } from 'react-i18next';
import _countries from 'i18n-iso-countries';
import {
  I18N_LOCALE_COOKIE,
  I18N_WORK_LOCATION_COOKIE,
  NUMBER_MAP_SYMBOLS_LOCALES,
} from '../Constants';
import countries from './countries';

// contains Country code and respective supported and default locale information.
// For any change in this file need to update same change to
// `/Platform/checkr/app/helpers/country_locales.rb`
// which is used for server side validation.
import countryLocales from './countryLocales.json';

const supportedLocales = {
  en: 'English',
  de: 'Deutsch',
  es: 'Español',
  fr: 'Français',
  en_XL: 'QA 1',
};

const CUSTOM_SUPPORTED_LOCALES = [
  'en_US',
  'de',
  'fr',
  'fr_CA',
  'es',
  'es_MX',
  'af',
];

const APP_ENV = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

const MONTH_DAY_YEAR_LOCALES = ['US', 'PH', 'KE', 'PR', 'PA'];

const DEFAULT_LOCALE = 'en';
const QA_LOCALE = 'en_XL';
const DEFAULT_WORKLOCATION = 'US';

/**
 * To check if the object are found for the locale
 * @param {object} object
 * @param {string} locale
 * @returns {boolean} true if found
 */
const isFound = (object, locale) => {
  return !!object[locale];
};

const DOMAIN = {
  domain: window?.location?.hostname.split('.').slice(-2).join('.'),
  sameSite: 'Lax',
};

// map country code to locale
export const mapCountry = (locale, country) => {
  const workLocation = i18n.getWorkLocation(country);
  if (workLocation && locale?.length === 2) {
    return `${locale}_${workLocation}`;
  }
  return locale || DEFAULT_LOCALE;
};

// returns country default locale
export const getCountryLocale = (code) => {
  if (code) {
    return countryLocales.find((c) => c.code === code)?.default_locale;
  }
  return '';
};

// returns storage values
export const getStorage = (key) => {
  let result = null;
  try {
    result = Cookie.get(key, DOMAIN) || localStorage.getItem(key);
  } catch (e) {
    result = null;
  }
  return result;
};

// set values in the storage
export const setStorage = (key, value) => {
  let result = null;
  try {
    if (key && value) {
      result = Cookie.set(key, value, DOMAIN);
      localStorage.setItem(key, value);
    }
  } catch (e) {
    result = null;
  }
  return result;
};

// remove key in the storage
export const removeStorage = (key) => {
  let result = null;
  try {
    if (Cookie.get(key, DOMAIN)) {
      result = Cookie.remove(key);
    }
    if (localStorage.getItem(key)) {
      result = localStorage.removeItem(key);
    }
  } catch (e) {
    result = null;
  }
  return result;
};

/**
 * Customized global i18n class for exposing the localized strings and date
 * @function i18n
 * @global
 * @package i18next,react-i18next,moment,i18n-iso-countries,html-react-parser
 */
export default class i18n {
  static DateFormats = {
    DATE_LONG: 'LL', // September 4, 1986
    DATE_SHORT: 'll', // Sep 4, 1986
    DATE_SLASH: 'L', // 09/04/1986
    DATETIME_LONG: 'LLL', // September 4, 1986 8:30 PM
    DATETIME_SHORT: 'lll', // Sep 4, 1986 8:30 PM
  };

  static defaultLocale = DEFAULT_LOCALE; // default fallback locale

  // default fallback locale with worklocation
  static defaultLocaleWithWorkLocation(country) {
    return `${this.defaultLocale}_${i18n.getWorkLocation(country)}`;
  }

  /**
   * Translate all the strings from resources using i18n library
   * @package i18next
   * @package react-i18next
   * @package i18next-browser-languagedetector
   * @member resources
   * @global
   */
  static init({
    resources,
    fallback = this.defaultLocale,
    locale = this.getLocale(),
  }) {
    i18next.use(initReactI18next).init({
      // we init with resources
      lng: locale, // if you're using a language detector, do not define the lng option
      resources, // string resources imported
      fallbackLng: fallback,
      debug: false,

      ns: ['translations'],
      defaultNS: 'translations',

      interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ',',
      },
      react: {
        useSuspense: true,
      },
    });
  }

  /**
   * @return i18n localized data from the resources
   * @param {string} key: resource key for getting localized data
   * @param {any} variable: concat the multiple values with the localized content [optional]
   */
  static getStr(key, variable = '') {
    const args = {};
    if (variable) {
      if (isString(variable)) {
        args.var = variable;
      }
      if (isNumber(variable)) {
        args.var = variable;
      }
      if (isArray(variable)) {
        variable.forEach((v, i) => {
          if (i === 0) {
            args.var = v;
          } else {
            args[`var${i + 1}`] = v;
          }
        });
      }
    }
    return i18next.t(
      key,
      typeof variable == 'object' && variable.constructor === Object
        ? variable
        : args,
    ); // returns translated data from the resources
  }

  /**
   * @description To set the locale and work location for application
   * @param {String} value locale to set for the application
   * @param {Object} resources load resources based on the conditions
   * @param {String} country used for moment date formats
   * @param {Boolean} useCookie true if international flow
   */
  static setLocale({ value, resources, country, useCookie = true }) {
    if (useCookie && this.isStorageEnabled()) {
      if (country) {
        setStorage(I18N_WORK_LOCATION_COOKIE, country);
      } else {
        removeStorage(I18N_WORK_LOCATION_COOKIE);
      }
    }
    let locale = mapCountry(value, country);
    // load available locale resources else fall back to load language or default resource
    if (resources && !isFound(resources, locale)) {
      locale = locale?.slice(0, 2);
      if (!isFound(resources, locale)) {
        locale = this.defaultLocaleWithWorkLocation(country);
        if (!isFound(resources, locale)) {
          locale = this.defaultLocale;
        }
      }
    }
    i18next.changeLanguage(locale);
    if (useCookie && this.isStorageEnabled()) {
      if (value) {
        setStorage(I18N_LOCALE_COOKIE, locale);
      } else {
        removeStorage(I18N_LOCALE_COOKIE);
      }
    }
    _moment.locale(this.getMomentLocale());

    // check if received locale is supported
    if (!isFound(supportedLocales, locale)) {
      locale = locale?.slice(0, 2);
      if (!isFound(supportedLocales, locale)) {
        locale = this.getLocale();
      }
    }

    if (document?.documentElement?.lang) {
      document.documentElement.lang = locale;
    }
  }

  // get localized date format
  static getLocalizedDateFormat(
    format = this.DateFormats.DATE_SLASH,
    locale = null,
  ) {
    if (locale) {
      return _moment().locale(locale).localeData().longDateFormat(format);
    }
    return _moment().localeData().longDateFormat(format);
  }

  // get localized date
  static getLocalizedDate(date, format = this.DateFormats.DATE_SLASH) {
    return _moment(date).format(i18n.getLocalizedDateFormat(format));
  }

  /**
   * @return localized HTML content
   * @param {string} key: resource key for getting localized data
   * @param {any} variable: concat the multiple values with the localized content [optional]
   */
  static renderHTML(key, variable = '') {
    const args = {};
    if (variable) {
      if (isString(variable)) {
        args.var = variable;
      }
      if (isNumber(variable)) {
        args.var = variable;
      }
      if (isArray(variable)) {
        variable.forEach((v, i) => {
          if (i === 0) {
            args.var = v;
          } else {
            args[`var${i + 1}`] = v;
          }
        });
      }
    }
    const result = i18next.t(
      key,
      typeof variable == 'object' && variable.constructor === Object
        ? variable
        : args,
    );
    return renderHTML(result);
  }

  // returns current detected locale
  static getLocale() {
    const cookieLocale = this.getCookieLocale();
    let locale = this.defaultLocale;
    if (cookieLocale) {
      locale = cookieLocale;
    } else {
      locale = this.getBrowserLocale();
    }

    if (!isFound(supportedLocales, locale)) {
      locale = locale?.slice(0, 2);
      if (!isFound(supportedLocales, locale)) {
        locale = this.defaultLocale;
      }
    }
    return locale;
  }

  // returns worklocation from cookie
  static getWorkLocation = (country) => {
    const cookieCountry = getStorage(I18N_WORK_LOCATION_COOKIE);
    if (!country && cookieCountry) {
      return cookieCountry;
    }
    return country || DEFAULT_WORKLOCATION;
  };

  // render the translations when changing the locale and refresh the page
  static renderTranslation() {
    return withTranslation();
  }

  // returns the browser locale
  static getBrowserLocale() {
    const locale = navigator?.language || navigator?.languages[0];
    return locale?.length > 2 ? locale?.replace('-', '_') : locale;
  }

  // returns the localized month year formatted date
  static getLocalizedMonthYear(date, format = this.getMonthYearPattern()) {
    return _moment(date).format(format) || null;
  }

  /**
   * To get constant string
   * @param {string} name
   * @param {*} id
   * @param {*} args
   * @returns localized constant string
   */
  static getConstStr(name, id, args = '') {
    if (name && id) {
      const stringId = camelCase(id);
      const temp = this.getStr(`${name}.${stringId}`, args);
      if (temp.indexOf(`${name}.`) !== 0) {
        return temp;
      }
    }
    return id;
  }

  // returns supported locales based on the conditions
  static getSupportedLocales(appEnv = APP_ENV.DEVELOPMENT) {
    let locales = supportedLocales;
    if (appEnv === APP_ENV.PRODUCTION) {
      locales = omit(locales, ['en_XL']);
    }
    return locales;
  }

  // localized country list matched with countries.js that returns all country list in English
  static getCountries() {
    const language = this.getLocale()?.slice(0, 2);
    /* eslint-disable global-require */
    /* eslint-disable import/no-dynamic-require */
    const cn = require(`i18n-iso-countries/langs/${language}.json`);
    _countries.registerLocale(cn);
    const countryList = [];
    countries.forEach((c) => {
      if (c?.value) {
        countryList.push({
          value: c.value,
          name: _countries.getName(c.value, language),
        });
      }
    });
    return countryList;
  }

  // returns localized country name
  static getCountry(country) {
    const language = this.getLocale()?.slice(0, 2);
    /* eslint-disable global-require */
    /* eslint-disable import/no-dynamic-require */
    const cn = require(`i18n-iso-countries/langs/${language}.json`);
    _countries.registerLocale(cn);
    return _countries.getName(country, language);
  }

  // returns the locale for moment lib
  static getMomentLocale() {
    const cookieLocale = mapCountry(this.getCookieLocale());
    const countryDefaultLocale = getCountryLocale(i18n.getWorkLocation());
    let momentLocale =
      this.normalizeToI18nNextFormat(cookieLocale).toLowerCase();
    if (
      cookieLocale &&
      momentLocales &&
      !momentLocales.locales().includes(momentLocale)
    ) {
      momentLocale =
        this.normalizeToI18nNextFormat(countryDefaultLocale).toLowerCase();
      if (
        countryDefaultLocale &&
        !momentLocales.locales().includes(momentLocale)
      ) {
        momentLocale = countryDefaultLocale.slice(0, 2);
        if (!momentLocales.locales().includes(momentLocale)) {
          momentLocale = this.defaultLocale;
        }
      }
    }
    // For all non-numeric (symbols) country fallback to cookie or english locale
    if (NUMBER_MAP_SYMBOLS_LOCALES.includes(momentLocale)) {
      const enLocale = MONTH_DAY_YEAR_LOCALES.includes(i18n.getWorkLocation())
        ? 'en-us'
        : 'en-gb';
      momentLocale =
        momentLocales &&
        [cookieLocale, enLocale].find((locale) =>
          momentLocales.locales().includes(locale),
        );
    }
    // import locale for localized formats
    try {
      require(`moment/locale/${momentLocale}`);
    } catch (e) {
      momentLocale = this.defaultLocale;
    }
    return momentLocale;
  }

  static getLocalesWithFallback() {
    const cookieLocale = this.normalizeToI18nNextFormat(
      mapCountry(this.getCookieLocale()),
    ).toLowerCase();
    const cookieLanguage = cookieLocale.slice(0, 2);
    const enLocale = MONTH_DAY_YEAR_LOCALES.includes(i18n.getWorkLocation())
      ? 'en-us'
      : 'en-gb';
    const fallbackLocales =
      cookieLanguage === this.defaultLocale
        ? [cookieLocale, enLocale]
        : [cookieLocale, cookieLanguage, enLocale];
    return fallbackLocales;
  }

  static getLocalizedDuration(date = null, unit = 'seconds') {
    if (date && _moment(date).isValid()) {
      const cookieLanguage = mapCountry(this.getCookieLocale()).slice(0, 2);
      return _momenttz.duration(date, unit).locale(cookieLanguage).humanize();
    }
    return date;
  }

  static getLocalizedDateOfBirth(date = null) {
    if (date && _moment(date).isValid()) {
      const locales = this.getLocalesWithFallback();
      let pattern = this.getLocalizedDateFormat(
        this.DateFormats.DATE_SHORT,
        locales,
      );
      pattern = pattern.replace('YYYY', '[XXXX]');
      return _moment(date).locale(locales).format(pattern);
    }
    return date;
  }

  static getLocalizedShortDateFormat(date = null) {
    if (date && _moment(date).isValid()) {
      const locales = this.getLocalesWithFallback();
      const pattern = this.getLocalizedDateFormat(
        this.DateFormats.DATE_SHORT,
        locales,
      );
      return _moment(date).locale(locales).format(pattern);
    }
    return date;
  }

  static getLocalizedShortDateTimeFormat(date = null) {
    if (date && _moment(date).isValid()) {
      const locales = this.getLocalesWithFallback();
      return _moment(date)
        .locale(locales)
        .format(this.DateFormats.DATETIME_SHORT);
    }
    return date;
  }

  static getLocalizedShortDateTimeFormatWithTz(date = null, pdf = false) {
    if (date && _moment(date).isValid()) {
      const locales = this.getLocalesWithFallback();
      const timezone = pdf ? 'UTC' : _momenttz.tz.guess();
      const pattern = this.getLocalizedDateFormat(
        this.DateFormats.DATETIME_SHORT,
        locales,
      );
      return _momenttz
        .tz(date, timezone)
        .locale(locales)
        .format(`${pattern} z`);
    }
    return date;
  }

  // returns the month year pattern
  static getMonthYearPattern(format = this.getLocalizedDateFormat()) {
    return format.replace(/d/gi, ' ').replace(/^\W{1,}|\W{1,}$|\W{3,3}/, '');
  }

  static normalizeToI18nNextFormat(value = '') {
    return value?.replace(/[_\s]/g, '-') || '';
  }

  // returns default country locale
  static getDefaultCountryLocale(code) {
    const defaultCountryLocale = getCountryLocale(code);
    if (isFound(supportedLocales, defaultCountryLocale)) {
      return {
        id: defaultCountryLocale,
        name: supportedLocales[defaultCountryLocale],
      };
    }
    return {
      id: this.defaultLocale,
      name: supportedLocales[this.defaultLocale],
    };
  }

  // returns cookie value
  static getCookieLocale() {
    return getStorage(I18N_LOCALE_COOKIE) || i18next.language || DEFAULT_LOCALE;
  }

  // returns pendo locale
  static getPendoLocale() {
    let locale = this.getCookieLocale().replace('-', '_');
    locale = locale === QA_LOCALE ? 'af' : locale;
    // check if cookie locale is pendo supported
    if (!CUSTOM_SUPPORTED_LOCALES.includes(locale)) {
      locale = locale?.slice(0, 2);
      if (!CUSTOM_SUPPORTED_LOCALES.includes(locale)) {
        locale = this.defaultLocale;
      }
    }
    return locale;
  }

  // returns reCAPTCHA locale
  static getGoogleRecaptchaLocale() {
    let captchaLocale = mapCountry(this.getCookieLocale());
    captchaLocale = captchaLocale === QA_LOCALE ? 'af' : captchaLocale;
    if (!CUSTOM_SUPPORTED_LOCALES.includes(captchaLocale)) {
      captchaLocale = captchaLocale.slice(0, 2);
    }
    return this.normalizeToI18nNextFormat(captchaLocale);
  }

  // returns true if storage enabled
  static isStorageEnabled() {
    try {
      return typeof localStorage === 'object' && navigator.cookieEnabled;
    } catch (e) {
      return false;
    }
  }
}

// initialize the moment locale
_moment.locale(i18n.getMomentLocale());
_momenttz.locale(i18n.getMomentLocale());

export const moment = _moment;
export const momenttz = _momenttz;
